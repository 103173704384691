import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {SectionTitle} from "../components/SectionTitle/styles"
import {
  GIheroSection,
  GIheroContent,
  GIheroImage,
  GIbuiltSection,
  GIquoteSection,
  GIindustrySection,
  IconBoxWrapper,
  IconBox
} from "../components/GreenInitiativePage/style"
import { Container } from "../components/Container"
import HeroImg from "../images/gihero.svg"
import TrioEnImg from "../images/gitrio.svg"
import TrioEsImg from "../images/gitrio_es.svg"
import HouseImg from "../images/gihouse.svg"
import FashionImg from "../images/gishirt.svg"
import EnergyImg from "../images/gisteker.svg"
import SekstantImage from "../images/gisekstant.svg"
import DesignImage from "../images/gipallete.svg"
import WineImg from "../images/giglass.svg"
import HospitalityImg from "../images/gipribor.svg"
import AgricultureImg from "../images/giship.svg"
import Img from "gatsby-image"

import localData from "../localization/green-initiative.json"
import {colors} from "../const/colors";
import {ButtonGf, ButtonLink} from "../components/Button/styles";

// const flags = {
//   "en": localData.locales[0].value,
//   "es": localData.locales[1].value
// }
const flags = [
  {
    name: "en",
    uri: localData.locales[0].value
  },
  {
    name: "es",
    uri : localData.locales[1].value
  }
]




const IndexPage = ({data, location, pageContext}) => {

  const langImages = {
    "en": TrioEnImg,
    "es": TrioEsImg
  }
  const lang = pageContext.lang
  const meta = data?.template?.locales.find(l => l.iso === lang)
  const metaStatic = localData.locales.find(l => l.iso === lang)

  return <Layout location={location} lang={lang} flags={flags}>
    <SEO
        title={ meta?.metaTitle || metaStatic.metaTitle}
        description={ meta?.metaDescription || metaStatic.metaDescription}
        keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
    />
    <GIheroSection>
      <Container>
        <GIheroContent>
          <h2>{localData.pageTitle[lang]}</h2>
          <p>{localData.sections.hero.description[lang]}</p>
          <ButtonLink uppercase to={localData.sections.hero.button.uri[lang]} background={colors.greenColor} color="white" bgHoverColor={colors.grayColor}>{localData.sections.hero.button.label[lang]}</ButtonLink>
        </GIheroContent>
        <GIheroImage>
          <img src={HeroImg} alt=""/>
        </GIheroImage>
      </Container>
    </GIheroSection>
    <GIbuiltSection>
      <Container>
        <SectionTitle>{localData.sections.built.title[lang]}</SectionTitle>
        <p>{localData.sections.built.descriptionTop[lang]}</p>
        <img src={langImages[lang]} alt=""/>
        <p>{localData.sections.built.descriptionBottom[lang]}</p>
      </Container>
    </GIbuiltSection>
    <GIquoteSection>
      <Container>
        <p className="qoute">
          {localData.sections.qoute.content[lang]}
        </p>
        <span className="name">{localData.sections.qoute.name[lang]}</span>
      </Container>
    </GIquoteSection>
    <GIindustrySection>
      <Container>
        <SectionTitle>{localData.sections.industrias.title[lang]}</SectionTitle>
        <IconBoxWrapper>
          <IconBox>
            <img src={HouseImg} alt=""/>
            <span className="title">
              {localData.sections.industrias.group.first[lang]}
            </span>
          </IconBox>
          <IconBox>
            <img src={FashionImg} alt=""/>
            <span className="title">
              {localData.sections.industrias.group.second[lang]}
            </span>
          </IconBox>
          <IconBox>
            <img src={EnergyImg} alt=""/>
            <span className="title">
              {localData.sections.industrias.group.third[lang]}
            </span>
          </IconBox>
          <IconBox>
            <img src={SekstantImage} alt=""/>
            <span className="title">
              {localData.sections.industrias.group.fourth[lang]}
            </span>
          </IconBox>
          <IconBox>
            <img src={DesignImage} alt=""/>
            <span className="title">
              {localData.sections.industrias.group.fifth[lang]}
            </span>
          </IconBox>
          <IconBox>
            <img src={WineImg} alt=""/>
            <span className="title">
              {localData.sections.industrias.group.sixth[lang]}
            </span>
          </IconBox>
          <IconBox>
            <img src={HospitalityImg} alt=""/>
            <span className="title">
              {localData.sections.industrias.group.seventh[lang]}
            </span>
          </IconBox>
          <IconBox>
            <img src={AgricultureImg} alt=""/>
            <span className="title">
              {localData.sections.industrias.group.eight[lang]}
            </span>
          </IconBox>
        </IconBoxWrapper>
        <ButtonLink margin="auto" width="200px" to={localData.sections.industrias.button.uri[lang]} color="white" background={colors.greenColor} bgHoverColor={colors.grayColor}>{localData.sections.industrias.button.label[lang]}</ButtonLink>
      </Container>
    </GIindustrySection>
  </Layout>
}

export default IndexPage
