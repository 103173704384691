import styled from "styled-components"
import { Container } from "../Container"
import { colors } from "../../const/colors";
import {ButtonLink, ButtonGf} from "../Button/styles"

export const GIheroSection = styled.section`
  padding:35px 0;
  @media screen and (min-width:992px) {
    padding:75px 0;
  }
  display:flex;
  ${Container} {
    display:flex;
    justify-content: space-between;
    align-items:center;
    flex-wrap:wrap;
  }
`
export const GIheroContent = styled.div`
    width:100%;
    padding:20px;
    line-height:30px;
    ${ButtonLink} {
      max-width:200px;
      text-align: center;
      margin-left:0px;
      margin-top:35px;
    }
    @media screen and (min-width:992px) {
       width:60%; 
    }
    h2 {
        color: ${colors.greenColor};
        font-size:2.3em;
        text-transform: uppercase;
    }
`
export const GIheroImage = styled.div`
    width:100%;
      @media screen and (min-width:992px) {
        width:40%;
      }
    img {
      display:block;
      width:100%;
    }
`

export const GIbuiltSection = styled.section`
  padding:35px 0;
  text-align: center;
  h2 {
    font-size:2em;
    text-align: center;
  }
  p {
    @media screen and (min-width:992px) {
      max-width:60%;
      margin:30px auto 30px auto
    }
  }
  img {
    width:100%;
    display:block;
    max-width:500px;
    margin: 50px auto;
    @media screen and (min-width:992px) {
      width:60%;
    }
  }
  @media screen and (min-width:992px) {
    padding:75px 0;
  }
`
export const GIquoteSection = styled.section`
  background: ${colors.lightBg};
  padding: 20px 0 40px 0;
  text-align: center;
  .qoute {
    margin:50px 0;
    width:100%;
    @media screen and (min-width:992px) {
      width:60%;
      max-width:1000px;
      margin:35px auto;
      text-align: center;
    }
  }
  .name {
    margin:25px 0;
    text-align:center;
    color: #454545;
    font-size:1em;
    font-weight:bold;
  }
`
export const GIindustrySection = styled.section`
  padding:35px 0;
  ${ButtonGf} {
    text-align: center;
    margin:auto;
    display:block;
  }
  h2 {
    text-align: center;
    font-size:2em;
    text-transform: uppercase;
    margin:35px 0;
  }
`
export const IconBoxWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  max-width:500px;
  margin:50px auto;
  flex-wrap:wrap
`
export const IconBox = styled.div`
  padding:0 15px;
  margin-bottom:30px;
  width:50%;
  @media screen and (min-width: 1170px) {
    width:25%;
  }
  img {
    display:block;
    margin:20px auto;
    width:auto;
    height:70px;
  }
  .title {
    width:100%;
    display:inline-block;
    text-align: center;
    color: #454545;
    
  }
`
